*{
    padding: 0;
    margin:0;
}
.navbar{
 line-height: 10vh;
 height: 10vh;
 display: flex;
 justify-content: space-between;
 align-items: center;
 background-color: rgb(251, 241, 227);
}

a{
    text-decoration: none;
}

.logo{
   color:rgb(20, 20, 53);
   font-size:20px;
   font-family: Lucida Handwriting;
   margin: 20px;
}
li {
    
    list-style: none;
}
.links{
    display:flex;
    justify-content: flex-end;
    width: 75%;

}
.links  li{
    padding: 0 10px;
    margin: 0 10px;
    font-size: 15px ;
    color: rgb(75, 4, 4);
    text-transform: capitalize;
    font-family: Segoe UI;
    font-weight: bold;

}




button{
    border:none;
font-size: 20px;
background: none;
}

.mobile{
    display: none;
    cursor: pointer;
}

@media (max-width:768px) {
   
   
    .logo{
        color:rgb(20, 20, 53);
        font-size:15px;
        font-weight:bold;
        font-family: Lucida Handwriting;
        margin:auto;
        
     }
   
    }
